dmx.Component('stripe-afterpay-message', {

  ui: {
    deprecated: true,
  },

  attributes: {
    amount: {
      type: Number,
      default: null,
      required: true,
    },

    currency: {
      type: String,
      default: 'USD',
      enum: ['USD', 'CAD', 'AUD', 'NZD', 'GBP'],
    },

    logoType: {
      type: String,
      default: 'badge',
      enum: ['badge', 'lockup'],
    },

    badgeTheme: {
      type: String,
      default: 'black-on-mint',
      enum: ['black-on-mint', 'black-on-white', 'mint-on-black', 'white-on-black'],
    },

    lockupTheme: {
      type: String,
      default: 'black',
      enum: ['black', 'white', 'mint'],
    },

    modalTheme: {
      type: String,
      default: 'mint',
      enum: ['mint', 'white'],
    },

    introText: {
      type: String,
      default: 'In',
      enum: ['In', 'in', 'Or', 'or', 'Pay', 'pay', 'Pay in', 'pay in'],
    },

    modalLinkStyle: {
      type: String,
      default: 'circled-info-icon',
      enum: ['circled-info-icon', 'learn-more-text', 'more-info-text'],
    },

    isNotEligible: {
      type: Boolean,
      default: false,
    },

    isCartNotEligible: {
      type: Boolean,
      default: false,
    },

    hideInterestFree: {
      type: Boolean,
      default: false,
    },

    hideWith: {
      type: Boolean,
      default: false,
    },

    max: {
      type: Number,
      default: undefined,
    },

    min: {
      type: Number,
      default: undefined,
    },

    hideLowerLimit: {
      type: Boolean,
      default: false,
    },

    hideUpperLimit: {
      type: Boolean,
      default: false,
    },

    amountIsFloat: {
      type: Boolean,
      default: false,
    },
  },

  render (node) {
    dmx.stripe.ready(() => {
      this._element = dmx.stripe.elements.create('afterpayClearpayMessage', {
        amount: (this.props.amountIsFloat ? 100 : 1) * this.props.amount,
        currency: this.props.currency,
        logoType: this.props.logoType,
        badgeTheme: this.props.badgeTheme,
        lockupTheme: this.props.lockupTheme,
        modalTheme: this.props.modalTheme,
        introText: this.props.introText,
        modalLinkStyle: this.props.modalLinkStyle,
        isEligible: !this.props.isNotEligible,
        isCartEligible: !this.props.isCartNotEligible,
        showInterestFree: !this.props.hideInterestFree,
        showWith: this.props.hideWith,
        max: this.props.max,
        min: this.props.min,
        showLowerLimit: !this.props.hideLowerLimit,
        showUpperLimit: !this.props.hideUpperLimit,
      });

      this._element.mount(node);
    });
  },

  destroy () {
    if (this._element) {
      this._element.destroy();
    }
  },
});
